import gsap, { Power1 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BannerLogo extends VScroll_Item {
  _overlay;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._overlay = GetBy.class('__overlay', __link)[0];

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => {
      const x = Maths.clamp(Maths.normalize(.5, .25, this.progress), 0, 1);
      gsap.set(this._overlay, { x: `${x * 100}%` });
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('banner-logo', ScrollItem__BannerLogo);