import gsap, { Power2 } from 'gsap';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BillboardProduct extends VScroll_Item {
  _bg;
  _title;
  _content;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._bg = GetBy.class('media-holder', __link)[0];
    gsap.set(this._bg, {
      opacity: 0,
      scaleX: 1.05,
      scaleY: 1.05
    });

    this.onShow = () => {
      let delay = 0;
      gsap.to(this._bg, {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        duration: 1.5,
        delay,
        ease: Power2.easeOut
      });
    };
    this.onHide = () => { };
    this.onMove = () => {
      if (isSmartphone) return;

      let opacity = 1;
      if (this.progress >= .5) opacity = Maths.clamp(Maths.map(this.progress, .65, .9, 1, 0), 0, 1);

      gsap.set(__link, { opacity });
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('billboard-product', ScrollItem__BillboardProduct);