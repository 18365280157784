import gsap, { Power2 } from 'gsap';
import { Basics } from '../_app/cuchillo/core/Basics';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__ProductList extends VScroll_Item {
  _filters;
  _products;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._filters = GetBy.class('__filter', __link);
    this._products = GetBy.class('__product', __link);
    gsap.set(this._products, { opacity: 0 });

    this._click = (e) => this.filter(e);
    for (let i = 0; i < this._filters.length; i++) {
      this._filters[i].addEventListener(Basics.clickEvent, this._click);
    }

    this.onShow = () => {
      for (let i = 0; i < this._products.length; i++) {
        const p = this._products[i];

        gsap.to(p, {
          opacity: 1,
          duration: 1,
          delay: .4 + .1 * i,
          ease: Power2.easeOut
        });
      }

    };
    this.onHide = () => {};
    this.onMove = () => {};
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  filter (e) {
    const btn = e.target;

    for (let i = 0; i < this._filters.length; i++) {
      const f = this._filters[i];

      if (f !== btn) f.classList.remove('--active');
      else f.classList.add('--active');
    }

    const filter = `categ-${btn.dataset.categ}`;

    gsap.to(this._products, {
      opacity: 0,
      duration: .2,
      onComplete: () => {
        for (let i = 0; i < this._products.length; i++) {
          const p = this._products[i];
    
          if (p.classList.contains(filter)) {
            p.style.display = 'block';
            gsap.to(p, {
              opacity: 1,
              duration: .8,
              ease: Power2.easeOut
            });
          } else {
            p.style.display = 'none';
          }
        }

        Scroll.resize();
      }
     });
  }

  dispose () {
    super.dispose();
    for (let i = 0; i < this._filters.length; i++) {
      this._filters[i].removeEventListener(Basics.clickEvent, this._click);
    }
  }
}

Scroll._registerClass('product-list', ScrollItem__ProductList);