import { gsap, Power2, Power3 } from 'gsap';

import { isTouch } from '../_app/cuchillo/core/Basics';
import Win from '../_app/cuchillo/windows/Window';
import VScroll from '../_app/cuchillo/scroll/VScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

class Catalogue extends Win {
  _toggle;
  _content;
  _isAnimating;

  constructor(__container) {
    super(__container, 'catalogue');

    this._isAnimating = false;

    this._toggle = GetBy.class('__toggle', __container)[0];
    this._content = GetBy.class('__content', __container)[0];

    this.directHide();

    if (!isTouch) {
      this.engine = new VScroll({
        container: this._content,
        isMain: false
      });
      this.engine.addAll('[data-scroll-catalogue-item]');
      this.engine.resize();

      this._call = () => this.loop();
    }
  }

  show__effect(__d = 0) {
    let delay = __d;

    document.body.classList.add('--nav-open');

    gsap.set(this._content, { x: '-100%' });
    gsap.to(this._content, {
      x: 0,
      duration: 1,
      delay,
      ease: Power2.easeInOut
    });

    delay += .2;
    const wrap = GetBy.selector('#Main .wrap')[0];
    if (wrap) {
      gsap.to(wrap, {
        x: this._content.offsetWidth / 4,
        opacity: .6,
        duration: .8,
        delay,
        ease: Power2.easeInOut
      });
    }

    delay += .1;
    gsap.to(this._toggle, {
      rotateZ: '225deg',
      delay,
      duration: 1,
      ease: Power3.easeInOut,
      onComplete: () => { this.afterShow() }
    });
  }

  hide__effect(__d = 0) {
    let delay = __d;
    document.body.classList.remove('--nav-open');

    gsap.to(this._toggle, {
      rotateZ: '45deg',
      duration: .8,
      delay,
      ease: Power3.easeOut
    });
    gsap.to(this._content, {
      x: '-100%',
      duration: .5,
      delay,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.afterHide();
      }
    });

    const wrap = GetBy.selector('#Main .wrap')[0];
    if (wrap) {
      gsap.to(wrap, {
        x: 0,
        duration: .5,
        opacity: 1,
        delay,
        ease: Power2.easeInOut
      });
    }
  }

  show() {
    if (this._isAnimating) return;

    this._isAnimating = true;

    super.show();

    if (this.engine) {
      // this.enableScroll();
      if (!this.engine.enabled) this.engine.enabled = true;
      gsap.ticker.add(this._call);
    }
  }

  afterShow() {
    super.afterShow();
    this._isAnimating = false;
  }

  directHide() {
    gsap.set(this._content, { x: '-100%', });
    super.directHide();
    gsap.ticker.remove(this._call);
  }

  hide() {
    if (this._isAnimating) return;

    this._isAnimating = true;

    super.hide();

    if (this.engine) {
      // this.disableScroll();
      if (this.engine.enabled) this.engine.enabled = false;
      gsap.ticker.remove(this._call);
    }
  }

  afterHide() {
    super.afterHide();
    this._isAnimating = false;
  }

  // enableScroll() {
  //   // Scroll.stop();
  // }

  // disableScroll() {
  //   // Scroll.start();
  // }

  loop() {
    this.engine.loop();
  }

  resize() {
    super.resize();

    if (this.engine) {
      this.engine.resize();
    }
  }
}

export const WinCatalogue = new Catalogue(GetBy.id('Catalogue'));