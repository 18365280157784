import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _overlay;
    _icon;

    static init () {
        this._container = GetBy.id('Preloader');
        this._overlay = GetBy.class('__overlay', this._container)[0];
        this._icon = GetBy.class('__icon', this._container)[0];
    }

    static update (__progress) {}

    static hide (__call) {
        gsap.to(this._overlay, {
            x: '100%',
            ease: Power1.easeOut,
            duration: 3
        });
        gsap.to(this._icon, {
            duration: .5,
            opacity: 0,
            delay: 3,
            ease: Power1.easeOut
        });
        gsap.to(this._container, {
            duration: .5,
            opacity: 0,
            delay: 3.5,
            ease: Power1.easeOut,
            onComplete: () => {
                this._container.style.display = "none";
                if(__call) __call();
            }
        });
    }
}
