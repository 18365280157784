import gsap, { Power2 } from 'gsap';

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { FormValidator } from '../_app/cuchillo/forms/FormValidator';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

const crossSvg = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_804_659)">
<path d="M31.8199 13.514H18.3059V0H13.514V13.514H0V18.3059H13.514V31.8199H18.3059V18.3059H31.8199V13.514Z" fill="white"/>
<g clip-path="url(#clip1_804_659)">
<path d="M27 14.3435H17.6565V5H14.3435V14.3435H5V17.6565H14.3435V27H17.6565V17.6565H27V14.3435Z" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_804_659">
<rect width="31.82" height="31.82" fill="white"/>
</clipPath>
<clipPath id="clip1_804_659">
<rect width="22" height="22" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg>
`;

class ScrollItem__Contact extends VScroll_Item {
  _btn;
  _fileContainer;
  _files;
  _formValidator;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._btn = GetBy.class('__add-file', __link)[0];
    this._fileContainer = GetBy.class('__files', __link)[0];
    this._files = 0;

    this._addFile = () => this.addFile();

    this._btn.addEventListener(Basics.clickEvent, this._addFile);

    this._formValidator = new FormValidator(GetBy.class('form', __link)[0]);
    
    this.onShow = () => {};
    this.onHide = () => {};
    this.onMove = () => {};
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  addFile () {    
    const span = document.createElement('span');
    span.classList = 'filename __filename';
    
    const input = document.createElement('input');
    input.classList = 'filename __filename';
    input.name = `file${this._files}`;
    input.type= 'file';
    input.setAttribute('data-form-required', '');
    input.setAttribute('data-form-file', '');

    input.click();
    input.addEventListener('change', () => {
      span.innerHTML = input.files[0].name;
      
      this._fileContainer.append(fieldset);
      this._files++;

      this._formValidator.dispose();
      this._formValidator = new FormValidator(GetBy.class('form', this.item)[0]);

      Scroll.resize();

      if (this._files === 9) this._btn.setAttribute('disabled', 'true');
    });
    
    const removeCall = () => {
      this.removeFile(fieldset, removeCall);
    };
    const btn = document.createElement('button');
    btn.classList.add('remove');
    btn.addEventListener(Basics.clickEvent, removeCall);
    btn.innerHTML = crossSvg;

    const fieldset = document.createElement('fieldset');
    fieldset.classList.add('--file');
    fieldset.append(input);
    fieldset.append(span);
    fieldset.append(btn);
  }
  
  removeFile (fieldset, call) {
    const btn = GetBy.class('remove', fieldset)[0];
    btn.removeEventListener(Basics.clickEvent, call);
    fieldset.remove();

    if (this._files === 9) this._btn.removeAttribute('disabled');

    this._files--;
    this.resetInputNames();
  }

  resetInputNames() {
    const files = GetBy.selector('input', this._fileContainer);
    for (let i = 0; i < files.length; i++) {
      files[i].setAttribute('name', `file${i}`);
    }
  }

  dispose () {
    super.dispose();
    this._btn.removeEventListener(Basics.click, this._addFile);
    this._formValidator.dispose();
  }
}

Scroll._registerClass('widget-contact', ScrollItem__Contact);