import { gsap, Power0, Power2 } from 'gsap';

import { isTouch } from '../core/Basics';
import VScroll from '../scroll/VScroll';
import { Accessibility } from '../core/Accessibility';
import { GetBy } from '../core/Element';
import EventDispatcher from '../core/EventDispatcher';
import Keyboard, { KEYS } from '../core/Keyboard';

export default class Sidemenu {
  static container = GetBy.id('Sidemenu');
  static content = GetBy.class('__content', this.container)[0];
  static btn = GetBy.selector('#Header .__toggle')[0];
  static texts = Array.from(GetBy.class('__text', this.container));
  static opacity = Array.from(GetBy.class('__opa', this.container));

  static ON_SHOW = "onshow";
  static ON_SHOW_END = "onshowend";
  static ON_HIDE = "onhide";
  static ON_HIDE_END = "onhideend";

  static STATE_OPEN = "OPEN";
  static STATE_CLOSE = "CLOSE";

  static options = {
    container: this.container,
    isMain: false
  };

  _state;
  _texts;
  _opacity;

  static get isOpen() { return this._state === Sidemenu.STATE_OPEN; }
  static get state() { return this._state };
  static set state(__state) {
    if (this._state === __state) return;

    this._state = __state;

    if (this.isOpen) {
      Keyboard.add(KEYS.ESC, 'Sidemenu_ESC', () => { this.hide(); });
      Accessibility.trap(this.container);
      EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW);
    } else {
      Keyboard.remove(KEYS.ESC, 'Sidemenu_ESC');
      Accessibility.removeTrap();
      EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE);
    }
  }

  static init() {
    Sidemenu.directHide();

    /* BUG RESIZE */
    if (!isTouch) {
      this.engine = new VScroll(this.options);
      this.engine.addAll('[data-scroll-sidemenu-item]');
      this.engine.resize();
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  static toggleState() {
    if (this.isOpen) { this.hide(); }
    else this.show();
  }

  static show(__d = 0) {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Sidemenu.STATE_OPEN;
    this.enableScroll();
    this.show__effect();
  }

  // SHOW
  static show__effect(__d = 0) {
    let delay = __d;

    document.body.classList.add('--nav-open');
    Sidemenu.btn.classList.add('--open');

    gsap.killTweensOf(Sidemenu.content);
    gsap.killTweensOf(Sidemenu.texts);
    gsap.killTweensOf(Sidemenu.opacity);

    gsap.set(Sidemenu.content, { x: '100%' });
    gsap.set(Sidemenu.texts, { y: '100%' });
    gsap.set(Sidemenu.opacity, { opacity: 0 });

    gsap.to(Sidemenu.content, {
      x: 0,
      duration: 1,
      delay,
      ease: Power2.easeInOut
    });

    const wrap = GetBy.selector('#Main .wrap')[0];
    if (wrap) {
      gsap.killTweensOf(wrap);

      delay += .2;
      gsap.to(wrap, {
        x: -1 * Sidemenu.content.offsetWidth / 4,
        opacity: .6,
        duration: .8,
        delay,
        ease: Power2.easeInOut
      });
    }

    Sidemenu.texts.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: 1,
        delay: delay + 0.03 * i,
        ease: Power2.easeOut
      });
    });

    delay += Sidemenu.texts.length * 0.04;
    Sidemenu.opacity.map((item, i) => {
      gsap.to(item, {
        opacity: 1,
        duration: 1.2,
        delay: delay + 0.15 * i,
        ease: Power2.easeOut
      });
    });

    gsap.delayedCall(delay + 0.2 * Sidemenu.opacity.length, () => { this.afterShow(); });
  }

  static afterShow() {
    EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW_END);
  }

  // HIDE
  static hide(__d = 0, __force) {
    this.disableScroll();
    this.state = Sidemenu.STATE_CLOSE;
    this.hide__effect();
  }

  static directHide() {
    this.disableScroll();
    gsap.set(Sidemenu.content, { x: '100%' });
    this.state = Sidemenu.STATE_CLOSE;
    this.afterHide();
  }

  static hide__effect(__d = 0) {
    let delay = __d;
    document.body.classList.remove('--nav-open');
    Sidemenu.btn.classList.remove('--open');

    gsap.killTweensOf(Sidemenu.content);
    gsap.killTweensOf(Sidemenu.texts);
    gsap.killTweensOf(Sidemenu.opacity);

    gsap.to(Sidemenu.content, {
      x: '100%',
      duration: .5,
      delay,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.afterHide();
      }
    });

    gsap.to(Sidemenu.texts, {
      y: '100%',
      duration: 0.4,
      delay,
      ease: Power2.easeOut
    });

    gsap.to(Sidemenu.opacity, {
      opacity: 0,
      duration: .3,
      delay: delay,
      ease: Power2.easeOut
    });

    const wrap = GetBy.selector('#Main .wrap')[0];
    if (wrap) {
      gsap.killTweensOf(wrap);

      gsap.to(wrap, {
        x: 0,
        duration: .5,
        opacity: 1,
        delay,
        ease: Power2.easeInOut
      });
    }
  }

  static afterHide() {
    this.container.style.visibility = 'hidden';
    this.container.setAttribute('aria-expanded', 'false');
    EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE_END);
  }

  static enableScroll() {
    if (this.engine && !this.engine.enabled) this.engine.enabled = true;
  }

  static disableScroll() {
    if (this.engine && this.engine.enabled) this.engine.enabled = false;
  }

  static loop() {
    if (this.engine && this.engine.enabled) this.engine.loop();
  }

  static resize() {
    if (this.isOpen && this.engine && this.engine.enabled) this.engine.resize();
  }
}
