import gsap, { Power1, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__Billboard extends VScroll_Item {
  _bg;
  _title;
  _text;
  _wrapper;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._wrapper = GetBy.class('__holder', __link)[0];

    this._bg = GetBy.class('media-holder', __link)[0];
    gsap.set(this._bg, {
      opacity: 0,
      scaleX: 1.05,
      scaleY: 1.05
    });

    this._title = new SplitText(GetBy.class('__title', __link)[0], {
      type: 'lines, words',
      linesClass: 'line-parent',
      wordsClass: 'line-child'
    }).words;
    gsap.set(this._title, { y: '100%' });

    this._text = GetBy.class('__text', __link);
    gsap.set(this._text, { opacity: 0 });

    this.onShow = () => {
      this._wrapper.style.pointerEvents = 'initial';

      let delay = 0;
      gsap.to(this._bg, {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        duration: 1.2,
        delay,
        ease: Power2.easeOut
      });

      delay += .2;
      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: delay + i * 0.1,
          ease: Power2.easeOut
        });
      }

      if (this._title.length < 6) delay += 0.25 * this._title.length;
      else delay += 0.1 * this._title.length;
      gsap.to(this._text, {
        opacity: 1,
        duration: 1.2,
        delay,
        ease: Power1.easeOut
      });
    };

    this.onHide = () => {
      this._wrapper.style.pointerEvents = 'none';
    };

    this.onMove = () => {
      if (isSmartphone) return;

      let opacity = 1;
      if (this.progress >= .5) opacity = Maths.clamp(Maths.map(this.progress, .5, .9, 1, 0), 0, 1);

      gsap.set(this._wrapper, { opacity });
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('billboard', ScrollItem__Billboard);