import gsap, { Power2, Power1 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__ProductContent extends VScroll_Item {
  _title;
  _text;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    
    gsap.set(__link, { opacity: 0 });

    this._title = new SplitText(GetBy.class('__title', __link)[0], { type: 'lines', linesClass: 'line-child' }).lines;
    new SplitText(GetBy.class('__title', __link)[0], {
      type: 'lines',
      linesClass: 'line-parent'
    });
    gsap.set(this._title, { y: '100%' });

    this._text = GetBy.class('__text', __link)[0];
    gsap.set(this._text, { opacity: 0 });

    this.onShow = () => {
      let delay = .4;
      gsap.to(this.item, {
        opacity: 1,
        duration: .4,
        delay,
        ease: Power1.easeOut
      });
      
      delay += .3;
      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: delay + i * 0.15,
          ease: Power2.easeOut
        });
      }

      if (this._title.length < 6) delay += 0.25 * this._title.length;
      else delay += 0.1 * this._title.length;
      gsap.to(this._text, {
        opacity: 1,
        duration: 1.2,
        delay,
        ease: Power1.easeOut
      });
    };
    this.onHide = () => {};
    this.onMove = () => {}
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('product-content', ScrollItem__ProductContent);